import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialSeven() {
  const settings = {
    dots:false,
    arrow: false,
    infinite: false,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: false,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      img: "img_79",
      desc: ` Thanks to Mitras AI, I walked into my interviews with confidence and nailed it! I highly recommend it to anyone serious about their job search.`,
      reviewerName: "Sarah J.",
      country: "USA",
      bgColor: "#FF47E2",
    },
    {
      img: "img_78",
      desc: ` I never realized how many small mistakes I was making in interviews until I started practicing with this AI platform. It's a game changer!`,
      reviewerName: "Michael L.",
      country: "USA",
      bgColor: "#00E5F3",
    },
    {
      img: "img_79",
      desc: ` I never thought I could feel so confident walking into an interview. This platform's mock interviews and detailed feedback made all the difference. Thank you!`,
      reviewerName: "Carlos R.",
      country: "USA",
      bgColor: "#FFCF24",
    }
  ];

  return (
    <Slider {...settings}>
      {TestimonilContent.map((val, i) => (
        <div className="item" key={i}>
          <div className="feedback-wrapper">
            <span className="ribbon" style={{ background: val.bgColor }}></span>
            <p>{val.desc}</p>
            <div className="d-flex align-items-center">
              {/*<img
                src={`images/media/${val.img}.png`}
                alt="icon"
                className="avatar"
              />/*/}
              <h6 className="name">
                {val.reviewerName},<span>{val.country}</span>
              </h6>
            </div>
          </div>
          {/* End .feedback-wrapper */}
        </div>
      ))}
    </Slider>
  );
}
