import React from "react";
import { Link } from "react-router-dom";
import { CONTACT_US_URL } from "../urls";

const Footer = () => {
  const socialContent = [
    {
      icon: "fa-twitter",
      link: "https://twitter.com/mitrasai_inc",
    },
    {
      icon: "fa-linkedin",
      link: "https://www.linkedin.com/company/mitras-ai/",
    },
  ];
  return (
    <div className="d-lg-flex align-items-center justify-content-between">
      {/*}
      <div className="logo" width={"100px"}>
        <a href="index.html">
          <img src="images/logo/mitras_logo.svg" width={"100px"} alt="logo" />
        </a>
  </div>*/}
      <ul className="style-none footer-nav d-flex flex-wrap justify-content-center md-pt-10 md-pb-20">
        <li>
          <Link to="#pricing">Pricing</Link>
        </li>
        <li>
          <Link to="#features">Features</Link>
        </li>
        <li>
          <Link to="#faq">FAQ</Link>
        </li>
        <li>
          <Link to={CONTACT_US_URL}>Contact Us</Link>
        </li>
        <li>
          <Link to="https://docs.google.com/document/d/1n0Mvd9FmvmCk6uOy8HtTZifdQtLsivm8AxMzL3Tw2RA/edit?usp=sharing" target="_blank">Privacy and TOS</Link>
        </li>
      </ul>
      <ul className="d-flex justify-content-center social-icon style-none">
        {socialContent.map((val, i) => (
          <li key={i}>
            <a href={val.link} target="_blank" rel="noreferrer">
              <i className={`fa ${val.icon}`}></i>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Footer;
