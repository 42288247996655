import React, { useState } from "react";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import { CONTACT_US_URL, WEBAPP_URL } from "../urls";

const Header = () => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 68) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  window.addEventListener("scroll", changeBackground);


  return (
    <>
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-one bg-none  fixed"
            : "theme-main-menu sticky-menu theme-menu-one bg-none"
        }
      >
              {/* End Header */}
        <div className="d-flex align-items-center justify-content-between" style={{marginRight: "0%"}}>
          <div className="logo" style={{maxWidth: "100px !important"}}>
            <Link to="/">
              <img src="images/logo/mitras_logo.svg" alt="brand logo" />
            </Link>
          </div>
          {/* End Logo */}

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="container nav-container">
            <div className="mob-header">	
                <button className="toggler-menu" onClick={handleClick}>	
                  <div className={click ? "active" : ""}>	
                    <span></span>	
                    <span></span>	
                    <span></span>	
                  </div>	
                </button>	
              </div>	
              <div
                className="navbar-collapse collapse"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <Scrollspy
                    className="navbar-nav  main-side-nav font-gordita"
                    items={[
                      "features",
                      "pricing",
                      "faq",
                      "contact",
                    ]}
                    currentClassName="active"
                    offset={-500}
                  >

                    <li className="nav-item">
                      <a href="#features" className="nav-link">
                        How it works
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#pricing" className="nav-link">
                        Pricing
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#faq" className="nav-link">
                        FAQ
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href={CONTACT_US_URL} className="nav-link">
                        Contact Us
                      </a>
                    </li>
                  </Scrollspy>

                  <ul className="right-button-group d-flex align-items-center justify-content-center">
                    <li>
                      <Link to= {WEBAPP_URL} className="signIn-action">
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link to={WEBAPP_URL} className="signUp-action">
                        Get Started
                      </Link>
                    </li>
                  </ul>
                  {/* End right-button-group  */}
                </div>
              </div>
            </div>
          </nav>
          {/* End nav */}
        </div>
        </div>

        {/* Mobile Menu Start */}
      <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>
        <div className="logo order-md-1">
          {/*}
          <Link to="/">
            <img src="images/mitras/mitras_logo.svg" alt="brand" />
          </Link>
          */}
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="images/icon/close.svg" alt="icon" />
          </div>
          {/* Mobile Menu close icon */}
        </div>

        <Scrollspy
          className="navbar-nav"
          id="theme-menu-list"
          items={[
            "features",
            "about",
            "product",
            "pricing",
            "feedback",
          ]}
          currentClassName="active"
          offset={-200}
        >
          <li className="nav-item">
            <a href="#home" className="nav-link" onClick={handleClick}>
              Home
            </a>
          </li>
          <li className="nav-item">
            <a href="#features" className="nav-link" onClick={handleClick}>
              How it Works
            </a>
          </li>
          <li className="nav-item">
            <a href="#pricing" className="nav-link" onClick={handleClick}>
              Pricing
            </a>
          </li>
          <li className="nav-item">
            <a href="#faq" className="nav-link" onClick={handleClick}>
              FAQ
            </a>
          </li>
          <li className="nav-item">
            <a href={CONTACT_US_URL} className="nav-link" onClick={handleClick}>
              Contact Us
            </a>
          </li>
        </Scrollspy>
      </div>
      {/* Mobile Menu End */}
      {/* /.theme-main-menu */}
    </>
  );
};

export default Header;
