import Header from "../components/Header";

import React from 'react'

const Policies = () => {
  return (
    <>
    <Header />
    </>
  )
}

export default Policies