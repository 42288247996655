import React from "react";
import ReactPlayer from "react-player";


const Video = () => {
  return (
    <>
      <ReactPlayer
      loop 
      playsInline
      controls
      //light="/images/mitras/Thumbnail.png"
      url="Interview-Promo-Draft-3.mp4"
      width="100%"
      height="100%"
      />
    </>
  );
};

export default Video;
