import React, { useState } from "react";
import { Helmet } from "react-helmet";
import HeroBannerEight from "../components/HeroBannerEight";
import TestimonialSeven from "../components/TestimonialSeven";
import Header from "../components/Header";
import Pricing from "../components/pricing-four/Pricing";
import Footer from "../components/Footer";
import FaqClassic from "../components/FaqClassic";
import { WEBAPP_URL } from "../urls";

const MitrasLanding = () => {
  // For header select menu
  const [click1, setClick1] = useState(false);
  const handleClick1 = () => setClick1(!click1);
  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title>
          Mitras AI || AI Powered Interview Mastery
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/*<HeaderLandingEditor />*/}
      <Header />

      {/* End Header Landing Editor */}

      {/* 	=============================================
				Theme Hero Banner
			==============================================  */}
      <div id="home">
        <HeroBannerEight />
      </div>

            {/* 
     =============================================
				Fancy Three Text Blokc
		============================================== */}
        <div id = "features"></div>
      <div className="fancy-feature-eight pt-150 md-pt-100">
        <div className="container">
          <div className="title-style-four text-center mb-60 md-mb-40" >
            <div className="row" >
              <div className="col-lg-10 m-auto">
                <h6>How it works</h6>
                <h2>
                Elevate Your Interview Game with Our{" "}
                  <span>
                  AI-Driven Platform{" "}
                    <img src="images/shape/line-shape-2.svg" alt="shape" />
                  </span>
                </h2>
              </div>
            </div>
          </div>

          <div className="block-style-twelve">
            <div className="row">
              <div
                className="col-lg-6 col-md-8 ms-auto order-lg-last"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="illustration-holder">
                  <img src="images/mitras/interview-screen.gif" alt="illustration" />
                </div>
              </div>
              <div
                className="col-lg-5 order-lg-first"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>Interview</h6>
                  <h2 className="font-rubik title">
                  Simulated Interviews that feel like the real thing.
                  </h2>
                  <p>
                  Practice with realistic AI-powered interview simulations tailored to your industry. Our adaptive AI coach challenges and engages you, building a strong foundation for success.
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twelve */}

          <div className="block-style-twelve">
            <div className="row">
              <div
                className="col-lg-6 col-md-8 me-auto"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="illustration-holder">
                  <img src="images/mitras/feedback_2.gif" alt="illustration" />
                </div>
              </div>
              <div
                className="col-lg-5"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>Insights</h6>
                  <h2 className="font-rubik title">
                    AI Powered Feedback
                  </h2>
                  <p>Receive instant, personalized feedback after each mock interview. Our advanced AI technology pinpoints areas for improvement and offers actionable advice, refining your interview skills.
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twelve */}

          <div className="block-style-twelve">
            <div className="row">
              <div
                className="col-lg-6 col-md-8 ms-auto order-lg-last"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="illustration-holder">
                  <img src="images/mitras/jobs-feature-2-loop.gif" alt="illustration" />
                </div>
              </div>
              <div
                className="col-lg-5 order-lg-first"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>Jobs</h6>
                  <h2 className="font-rubik title">
                  Curated Job Matches and On-Demand Interview Practice.
                  </h2>
                  <p>
                  Discover daily job matches personalized to your preferences through our integrated jobs board feature. Click "practice" and dive into an interview simulation that's tailor-made for the role at hand.
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twelve */}

          <div className="block-style-twelve">
            <div className="row">
              <div
                className="col-lg-6 col-md-8 me-auto"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="illustration-holder">
                  <img src="images/mitras/confidence_2_1.svg" alt="illustration" />
                </div>
              </div>
              <div
                className="col-lg-5"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>Platform</h6>
                  <h2 className="font-rubik title">
                    Improve your Confidence and Interviewing Ability
                  </h2>
                  <p>
                  Boost your confidence and sharpen your abilities with consistent practice. Tackle even the toughest questions with poise, and become the candidate every employer desires.
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twelve */}
        </div>
      </div>
      {/* /.fancy-feature-eight */}
      
            {/*  =====================================================
				Pricing Section Four
			===================================================== */}
      <div className="pricing-section-four pt-200 md-pt-100" id="pricing">
        <div className="container">
          <div className="row">
            <div className="col-xl-10  m-auto">
              <div className="title-style-six text-center">
                <h2>
                Choose from our flexible pricing plans to get started and invest in your future today!
                </h2>
              </div>
              {/* /.title-style-six */}
            </div>
          </div>
        </div>
        {/* End .container */}

        <Pricing />
      </div>
      {/*  /.pricing-section-four */}


      {/* =====================================================
				Client Feedback Slider Six
			===================================================== */}
      <div
        className="client-feedback-slider-six pt-170 md-pt-120"
        id="feedback"
      >
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-7 col-lg-9 m-auto"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div className="title-style-eight text-center">
                  <h6>FEEDBACK</h6>
                  <h2>Empowering Job Seekers, One Interview at a Time.</h2>
                </div>
              </div>
            </div>
            {/* End .row */}
          </div>
          {/* End .container */}
          <div className="clientSliderSix">
            <TestimonialSeven />
          </div>
        </div>
        {/* /.inner-container */}
      </div>
      {/* /.client-feedback-slider-six */}
      {/* 
     =============================================
			Faq Classic
		============================================== */}
      <div className="faq-classic with-bg" id = "faq">
        <img
          src="images/shape/86.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/87.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <img
          src="images/shape/88.svg"
          alt="shape"
          className="shapes shape-three"
        />
        <img
          src="images/shape/89.svg"
          alt="shape"
          className="shapes shape-four"
        />
        <img
          src="images/shape/90.svg"
          alt="shape"
          className="shapes shape-five"
        />
        <img
          src="images/shape/91.svg"
          alt="shape"
          className="shapes shape-six"
        />
        <div className="container">
          <div className="title-style-four text-center mb-100 md-mb-70">
            <div className="row">
              <div className="col-lg-7 m-auto">
                {/*<h6>FAQ’s</h6>*/}
                <h2>
                  <span>
                    Frequently Asked Questions{" "}
                    <img src="images/shape/line-shape-2.svg" alt="" />
                  </span>
                </h2>
              </div>
              {/* End .col */}
            </div>
          </div>
          {/* End title */}
          <FaqClassic />
        </div>
      </div>
      {/* /.faq-classic */}
      {/* 	=====================================================
				Fancy Short Banner Nine
			===================================================== */}
      <div className="fancy-short-banner-nine mt-10 md-mt-10">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-8 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-seven text-center">
                <h2>Start your Journey to Interview Mastery now!</h2>
                <p>Try it risk free - no credit card required</p>
              </div>
              {/*  /.title-style-six */}
            </div>
          </div>
          {/* End .row */}
          <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
            <div
              className={
                click1 ? "download-btn show" : "download-btn"
              }
            >
              <button
                href={WEBAPP_URL}
                className="theme-btn-cta"
              >
                Get Free Interview
              </button>
            </div>
          </div>
        </div>
        {/*  /.container */}
        <img
          src="images/shape/177.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/178.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
      {/*  /.fancy-short-banner-nen */}

      {/* =====================================================
				Footer Style Seven
			===================================================== */}
      <footer className="theme-footer-nine mt-10 md-mt-10">
        <div className="lg-container">
          <div className="container">
            <Footer />
          </div>
        </div>
        {/* /.lg-container */}
        
      </footer>
      <div style={{ width: "100%", textAlign:"center", fontSize: "10px"}}>©️ Mitras AI, Inc. 2023</div>
      {/* /.theme-footer-seven */}
    </div>
  );
};

export default MitrasLanding