import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import "bootstrap/dist/js/bootstrap";
import ScrollTopBehaviour from "./components/ScrollTopBehaviour";
import MitrasLanding from "./pages/MitrasLanding";
import Policies from "./pages/Policies";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  return (
    <>
      <Helmet>
        <title>Mitras AI || AI Powered Interview Mastery</title>
        <meta property="og:site_name" content="mitras-ai-interview" />
        <meta
          property="og:url"
          content="https://interview.mitras.ai"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Mitras AI || AI Powered Interview Mastery"
        />
        <meta
          name="keywords"
          content="agency, bootstrap 5, business, clean, corporate, creative, fullpage, minimal, modern, multipurpose, parallax, personal, photography, portfolio, showcase, Digital marketing agency, Digital marketing company, Digital marketing services, sass, software company"
        />
        <meta
          name="description"
          content="Elevate Your Interview Game with Our AI-Driven Platform"
        />
        <meta name="description" content="AI Powered Interview Mastery" />
      </Helmet>
      {/* End Seo Helmt */}
      <BrowserRouter>

      <ScrollToTop />
      {/* End Scroll top */}

      <ScrollTopBehaviour />
      <Routes>
        <Route path="/" element={<MitrasLanding />} />
        <Route path="/policies" element={<Policies />} />
      </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;