import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "Can I take mock interviews for any job?",
    desc: `Yes! We have an easy to use UI where you can enter the job title and job description you want to interview for. Either come up with your own or paste a job description from the internet!`,
    expand: "a",
  },
  {
    title: "Am I able to download my transcripts?",
    desc: `Yes you can download transcripts as well as the generated feedback report.`,
    expand: "b",
  },
  {
    title: "Are my interviews recorded?",
    desc: `Not yet, but that feature will be available soon!`,
    expand: "c",
  },
];
const FaqContentTwo = [
  {
    title: "Can I get feedback on my mock interviews?",
    desc: `Yes, we have AI generated feedback available on all mock interviews. The feedback analyzes the content of your response and suggests improvements. More features on this coming soon!`,
    expand: "e",
  },
  {
    title: " Can I cancel my subscription at any time?",
    desc: `Yes, you can cancel your subscription at any time. Based on your plan (monthly, annual), your subscription will end at the conclusion of your current billing cycle.`,
    expand: "f",
  },
  {
    title: "How many times can I retake a mock interview?",
    desc: `With a monthly or annual subscription, you can take the allotted number of mock interviews as per your chosen plan. You can retake the interviews as many times as you want within your allotted limit.`,
    expand: "g",
  },
];

const FaqClassic = () => {
  return (
    <div className="accordion-style-four">
      <div className="faq-wrraper">
        <div className="row">
          <div className="col-lg-6">
            <Accordion allowZeroExpanded>
              <div className="row">
                {FaqContent.map((item, i) => (
                  <div className="col-lg-12" key={i}>
                    <AccordionItem className="card">
                      <AccordionItemHeading className="card-header">
                        <AccordionItemButton>
                          <h5 className="mb-0">
                            <button className="btn btn-link">
                              {item.title}
                            </button>{" "}
                          </h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      {/* Accordion Heading */}
                      <AccordionItemPanel className="card-body fadeInUp">
                        <p>{item.desc}</p>
                      </AccordionItemPanel>
                      {/* Accordion Body Content */}
                    </AccordionItem>
                  </div>
                ))}
              </div>
            </Accordion>
          </div>
          {/* End.col */}

          <div className="col-lg-6">
            <Accordion allowZeroExpanded>
              <div className="row">
                {FaqContentTwo.map((item, i) => (
                  <div className="col-lg-12" key={i}>
                    <AccordionItem className="card">
                      <AccordionItemHeading className="card-header">
                        <AccordionItemButton>
                          <h5 className="mb-0">
                            <button className="btn btn-link">
                              {item.title}
                            </button>{" "}
                          </h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      {/* Accordion Heading */}
                      <AccordionItemPanel className="card-body fadeInUp">
                        <p>{item.desc}</p>
                      </AccordionItemPanel>
                      {/* Accordion Body Content */}
                    </AccordionItem>
                  </div>
                ))}
              </div>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqClassic;
